<template>
  <div
    class="rounded-lg p-3 shadow-2xl bg-white mb-2 text-black"
    v-loading="isLoading"
  >
    <div class="flex flex-col md:flex-row gap-2 items-center justify-between">
      <h3 class="fs-20 fw-500">{{ $t("Lịch hẹn khám lại") }}</h3>

      <el-button type="primary" size="small" @click="handleOpenModalListHSBA">
        {{ $t("Đính kèm vào HSBA") }}
      </el-button>
    </div>
    <div class="mt-3 flex items-center justify-between gap-3">
      <div class="flex gap-3 items-center">
        <svg
          @click="
            $router.push({
              name: 'AppointmentDetailV2',
              params: { id: info.id },
            })
          "
          class="cursor-pointer"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="29"
            height="29"
            rx="3.5"
            stroke="#20409B"
          />
          <mask
            id="mask0_35008_774"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="6"
            y="6"
            width="18"
            height="18"
          >
            <rect
              x="6.92859"
              y="6.92871"
              width="16.1429"
              height="16.1429"
              fill="#D9D9D9"
              stroke="#20409B"
            />
          </mask>
          <g mask="url(#mask0_35008_774)">
            <path
              d="M15 17.8701C15.8962 17.8701 16.6583 17.5563 17.2857 16.9289C17.913 16.3015 18.2268 15.5394 18.2268 14.6433C18.2268 13.7471 17.913 12.985 17.2857 12.3577C16.6583 11.7303 15.8962 11.4165 15 11.4165C14.1039 11.4165 13.3418 11.7303 12.7144 12.3577C12.087 12.985 11.7732 13.7471 11.7732 14.6433C11.7732 15.5394 12.087 16.3015 12.7144 16.9289C13.3418 17.5563 14.1039 17.8701 15 17.8701ZM15 16.5593C14.4676 16.5593 14.0154 16.3731 13.6428 16.0005C13.2702 15.6279 13.084 15.1757 13.084 14.6433C13.084 14.1108 13.2702 13.6587 13.6428 13.286C14.0154 12.9134 14.4676 12.7272 15 12.7272C15.5325 12.7272 15.9846 12.9134 16.3573 13.286C16.7299 13.6587 16.9161 14.1108 16.9161 14.6433C16.9161 15.1757 16.7299 15.6279 16.3573 16.0005C15.9846 16.3731 15.5325 16.5593 15 16.5593ZM15 19.9879C13.2644 19.9879 11.6835 19.5035 10.257 18.5347C8.83193 17.5668 7.7984 16.2698 7.15632 14.6433C7.7984 13.0168 8.83193 11.7197 10.257 10.7518C11.6835 9.78301 13.2644 9.29863 15 9.29863C16.7356 9.29863 18.3165 9.78301 19.743 10.7518C21.1681 11.7197 22.2016 13.0168 22.8437 14.6433C22.2016 16.2698 21.1681 17.5668 19.743 18.5347C18.3165 19.5035 16.7356 19.9879 15 19.9879Z"
              fill="#20409B"
              stroke="#20409B"
              stroke-width="0.025"
            />
          </g>
        </svg>

        <p class="mb-0 f">
          Thời gian tái khám:
          <b class="col">
            {{ getStartTime() }}
            <!-- {{ getTime(info.start_time) }} -
            {{ formatDateDMY(info.start_time) || ''}} -->
          </b>
        </p>
      </div>
      <svg
        @click="handleViewPrint"
        class="cursor-pointer"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 14H17C17.2652 14 17.5196 13.8946 17.7071 13.7071C17.8946 13.5196 18 13.2652 18 13V7C18 6.73478 17.8946 6.48043 17.7071 6.29289C17.5196 6.10536 17.2652 6 17 6H3C2.73478 6 2.48043 6.10536 2.29289 6.29289C2.10536 6.48043 2 6.73478 2 7V13C2 13.2652 2.10536 13.5196 2.29289 13.7071C2.48043 13.8946 2.73478 14 3 14H4V9H16V14ZM4 4V0H16V4H17C17.7956 4 18.5587 4.31607 19.1213 4.87868C19.6839 5.44129 20 6.20435 20 7V13C20 13.7956 19.6839 14.5587 19.1213 15.1213C18.5587 15.6839 17.7956 16 17 16H16V20H4V16H3C2.20435 16 1.44129 15.6839 0.87868 15.1213C0.31607 14.5587 0 13.7956 0 13L0 7C0 6.20435 0.31607 5.44129 0.87868 4.87868C1.44129 4.31607 2.20435 4 3 4H4ZM6 18H14V11H6V18ZM6 4H14V2H6V4Z"
          fill="#03267A"
        />
      </svg>
    </div>
    <ModalListHSBA
      ref="ModalListHSBA"
      :person_id="detailExam.person_id"
      :appt_id="info.id"
      :currentPatientVisit="detailExam"
    />
    <GiayHenKhamLaiDetail
      ref="GiayHenKhamLaiDetail"
      :is-patient-visit="true"
      custom-width="100%"
      :info="detailExam"
      :apptDetail="info"
      v-show="false"
    />
  </div>
</template>
<script>
import appUtils from '../../utils/appUtils'
import ModalListHSBA from './ModalListHSBA.vue'
import GiayHenKhamLaiDetail from '../../components/ElectronicMedicalRecords/GiayHenKhamLai/Detail.vue'

export default {
  name: 'ReExam',
  props: {
    info: Object,
    detailExam: Object
  },
  components: { ModalListHSBA, GiayHenKhamLaiDetail },
  data () {
    return {
      isShowModalView: false,
      appUtils,
      isLoading: false
    }
  },
  methods: {
    handleViewPrint () {
      this.isLoading = true
      this.$refs.GiayHenKhamLaiDetail.onPrintNe(() => {
        this.isLoading = false
      })
    },
    handleOpenModalListHSBA () {
      this.$refs.ModalListHSBA.handleOpen()
    },
    getTime (date_time) {
      return `${new Date(date_time)
        .getUTCHours()
        .toString()
        .padStart(2, '0')}:${new Date(date_time)
        .getUTCMinutes()
        .toString()
        .padStart(2, '0')}`
    },
    formatDateDMY (date_time) {
      return date_time && window.moment(date_time).format('DD/MM/YYYY')
    },
    getStartTime () {
      const date = window.moment(this.info?.start_time).subtract(7, 'hours')
      return appUtils.formatDateTime(date)
    }
  }
}
</script>
<style lang="scss" scoped>
//
</style>