<template>
  <div>
    <el-dialog
      custom-class="cs-dialog"
      fullscreen
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      append-to-body
    >
      <div class="container-fluid">
        <div class="text-center text-2xl font-semibold mb-2">
          {{
            isCreate ? "Thêm mới Đơn thuốc" : "Chỉnh sửa thông tin Đơn thuốc"
          }}
        </div>
        <SelectPrescriptionForm
          :refreshPrescriptionForm="refreshPrescriptionForm"
          :clearSelected="clearSelected"
          @onSelect="handleChangePrescriptionForm"
        />
        <el-form
          v-if="form"
          :model="form"
          ref="formRef"
          label-width="120px"
          label-position="top"
        >
          <div class>
            <div class="row">
              <el-form-item
                class="mb-2 col-xl-3 fs-16 cs-form"
                label="Tên đơn thuốc"
                prop="name"
              >
                <el-input
                  v-model="prescription.name"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item
                class="mb-2 col-xl-2 fs-16 cs-form"
                label="Ngày kê đơn"
                prop="date"
              >
                <el-date-picker
                  class="w-full"
                  v-model="prescription.date"
                  type="date"
                ></el-date-picker>
              </el-form-item>

              <el-form-item
                class="mb-2 col-xl-3 fs-16 cs-form"
                label="Bác sĩ kê đơn"
                prop="prescribed_doctor"
              >
                <div class="form-group">
                  <el-input
                    disabled
                    v-model="prescription.prescribed_doctor"
                    autocomplete="off"
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item
                prop="prescribed_clinic"
                class="mb-2 col-xl-4 fs-16 cs-form"
                label="Cơ sở Y Tế"
              >
                <el-input
                  v-model="prescription.prescribed_clinic"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item
                prop="description"
                class="mb-2 col fs-16 cs-form"
                label="Lời dặn của Bác sĩ"
              >
                <el-input
                  v-model="prescription.description"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </div>
          </div>

          <!-- WORK -->

          <div class="row">
            <div class="col-xl-5">
              <div class="flex mt-2 justify-between">
                <div class="w-full">
                  <div class="border border-1 p-3 border-indigo-300">
                    <div class="flex justify-between">
                      <span class="text-black text-xl">Thông tin thuốc</span>
                      <div>
                        <input
                          class="form-check-input mr-2"
                          type="checkbox"
                          v-model="form.is_monitor"
                          autocomplete="off"
                          true-value="1"
                          false-value="0"
                          id="is_monitor"
                        />
                        <label
                          class="text-gray-500 text-base select-none cursor-pointer"
                          >Theo dõi tuân thủ thuốc</label
                        >
                      </div>
                    </div>
                    <!-- Rule -->
                    <div class="mt-2 relative">
                      <span class>Chọn kho Thuốc</span>

                      <el-select
                        class="w-full"
                        v-model="inventoryMedicine"
                        filterable
                        placeholder="Chọn kho thuốc"
                        remote
                        reserve-keyword
                        @change="handleChangeInventory"
                        :remote-method="getInventoryMedicines"
                        :loading="loading_medicines"
                      >
                        <el-option
                          v-for="item in listInventory"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="absolute top-7 right-2"
                      >
                        <path
                          d="M16.9999 9.16994C16.8126 8.98369 16.5591 8.87915 16.2949 8.87915C16.0308 8.87915 15.7773 8.98369 15.5899 9.16994L11.9999 12.7099L8.45995 9.16994C8.27259 8.98369 8.01913 8.87915 7.75495 8.87915C7.49076 8.87915 7.23731 8.98369 7.04995 9.16994C6.95622 9.26291 6.88183 9.37351 6.83106 9.49537C6.78029 9.61723 6.75415 9.74793 6.75415 9.87994C6.75415 10.012 6.78029 10.1427 6.83106 10.2645C6.88183 10.3864 6.95622 10.497 7.04995 10.5899L11.2899 14.8299C11.3829 14.9237 11.4935 14.9981 11.6154 15.0488C11.7372 15.0996 11.8679 15.1257 11.9999 15.1257C12.132 15.1257 12.2627 15.0996 12.3845 15.0488C12.5064 14.9981 12.617 14.9237 12.7099 14.8299L16.9999 10.5899C17.0937 10.497 17.1681 10.3864 17.2188 10.2645C17.2696 10.1427 17.2957 10.012 17.2957 9.87994C17.2957 9.74793 17.2696 9.61723 17.2188 9.49537C17.1681 9.37351 17.0937 9.26291 16.9999 9.16994Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                    <div class="row mt-2">
                      <div class>
                        <SelectMedicine
                          :clearCount="clearCount"
                          :medicine="form.medicine"
                          :inventoryMedicine="inventoryMedicine"
                          :listInventory="listInventory"
                          @onClearMedicine="
                            () => {
                              form.medicine = null;
                              form.amount_unit = '';
                              handleClearData();
                            }
                          "
                          @onUpdateMedicine="handleUpdateMedicine"
                        />
                      </div>
                    </div>
                    <div class="mt-3 flex justify-between">
                      <div>
                        <el-radio
                          @change="handleSelectedIsByDay"
                          v-model="form.is_by_day"
                          :label="1"
                          >Kê đơn theo ngày</el-radio
                        >
                        <el-radio
                          @change="handleSelectedIsBySection"
                          v-model="form.is_by_day"
                          :label="2"
                          >Kê đơn theo buổi</el-radio
                        >
                      </div>
                      <div v-if="form.qty">
                        <span>Tồn kho:</span>
                        <span class="ml-1 font-bold">{{ form.qty }}</span>
                      </div>
                    </div>
                    <div
                      v-if="form.is_by_day === 1"
                      class="flex items-center gap-4 mt-3"
                    >
                      <el-form-item
                        class="cs-form m-0"
                        style="flex: 1"
                        prop="total"
                        label="Số ngày"
                        :rules="[
                          {
                            required: true,
                            message: 'Trường này không được để trống',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input
                          @input="handleUpdateSuggestMedicine"
                          v-model="form.total"
                          :disabled="checkStartTime(form.status)"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        class="cs-form m-0"
                        style="flex: 1"
                        label="Tổng số"
                        prop="amount"
                        :rules="[
                          {
                            required: true,
                            message: 'Trường này không được để trống',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input-number
                          class="text-left"
                          v-model.number="form.amount"
                          :disabled="checkStartTime(form.status)"
                          @change="calcGapDay(form)"
                          :controls="false"
                        ></el-input-number>
                      </el-form-item>
                      <el-form-item
                        class="cs-form m-0"
                        style="flex: 1"
                        label="Lần / ngày"
                        :prop="'freq_per_day'"
                        :rules="[
                          {
                            required: true,
                            message: 'Trường này không được để trống',
                            trigger: 'blur',
                          },
                          {
                            type: 'number',
                            message: 'Trường này chỉ chấp nhận số',
                          },
                        ]"
                      >
                        <div class="d-flex">
                          <el-input
                            @input="handleUpdateSuggestMedicine"
                            v-model.number="form.freq_per_day"
                            :disabled="checkStartTime(form.status)"
                            autocomplete="off"
                          >
                            <template slot="append">Lần</template>
                          </el-input>
                        </div>
                      </el-form-item>
                    </div>
                    <div
                      v-if="form.is_by_day === 1"
                      class="flex items-center gap-4 mt-3"
                    >
                      <el-form-item
                        class="cs-form m-0"
                        style="flex: 1"
                        label="Mỗi lần sử dụng"
                        :prop="'amount_per_one'"
                        :rules="[
                          {
                            required: true,
                            message: 'Trường này không được để trống',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <div
                          class="el-input el-input-group el-input-group--append"
                        >
                          <el-input-number
                            class="text-left"
                            v-model.number="form.amount_per_one"
                            :disabled="checkStartTime(form.status)"
                            :controls="false"
                            @input="handleUpdateSuggestMedicine"
                          ></el-input-number>
                          <div class="el-input-group__append h-8 leading-none">
                            Đơn vị
                          </div>
                        </div>
                      </el-form-item>
                      <el-form-item
                        class="mb-0 cs-form"
                        style="flex: 1"
                        label="Đường dùng"
                        :prop="'route_of_use'"
                        :rules="[
                          {
                            required: true,
                            message: 'Trường này không được để trống',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <div class="d-flex">
                          <el-input
                            v-model.number="form.route_of_use"
                            @input="handleUpdateSuggestMedicine"
                            :disabled="checkStartTime(form.status)"
                            autocomplete="off"
                          ></el-input>
                        </div>
                      </el-form-item>
                      <el-form-item
                        class="cs-form m-0"
                        style="flex: 1"
                        label="Đơn vị"
                        prop="amount_unit"
                        :rules="[
                          {
                            required: true,
                            message: 'Trường này không được để trống',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <div class="d-flex">
                          <el-input
                            v-model="form.amount_unit"
                            :disabled="
                              checkStartTime(form.status) ||
                              Boolean(this.form.medicine) ||
                              true
                            "
                            autocomplete="off"
                          />
                        </div>
                      </el-form-item>
                    </div>

                    <div
                      v-if="form.is_by_day === 2"
                      class="flex gap-4 mt-4 flex-wrap"
                    >
                      <el-form-item
                        class="m-0 cs-form"
                        style="flex: 1"
                        prop="total"
                        label="Số ngày"
                        :rules="[
                          {
                            required: true,
                            message: 'Trường này không được để trống',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input
                          @input="handleUpdateSuggestMedicine"
                          v-model="form.total"
                          :disabled="checkStartTime(form.status)"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        class="cs-form m-0"
                        style="flex: 1"
                        label="Tổng số"
                        prop="amount"
                        :rules="[
                          {
                            required: true,
                            message: 'Trường này không được để trống',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input-number
                          class="text-left"
                          v-model.number="form.amount"
                          :disabled="checkStartTime(form.status)"
                          @change="calcGapDay(form)"
                          :controls="false"
                        ></el-input-number>
                      </el-form-item>

                      <el-form-item
                        class="cs-form m-0"
                        style="flex: 1"
                        label="SL Sáng"
                        prop="amountMorning"
                      >
                        <div class="d-flex">
                          <el-input
                            v-model="form.amountMorning"
                            :disabled="checkStartTime(form.status)"
                            autocomplete="off"
                            @input="handleUpdateSuggestMedicine"
                          />
                        </div>
                      </el-form-item>
                      <el-form-item
                        class="cs-form m-0"
                        style="flex: 1"
                        label="SL Trưa"
                        prop="amountNoon"
                      >
                        <div class="d-flex">
                          <el-input
                            v-model="form.amountNoon"
                            :disabled="checkStartTime(form.status)"
                            autocomplete="off"
                            @input="handleUpdateSuggestMedicine"
                          />
                        </div>
                      </el-form-item>
                    </div>
                    <div
                      v-if="form.is_by_day === 2"
                      class="flex items-center gap-4 mt-4"
                    >
                      <el-form-item
                        class="cs-form m-0"
                        style="flex: 1"
                        label="SL Chiều"
                        prop="amountAfternoon"
                      >
                        <div class="d-flex">
                          <el-input
                            v-model="form.amountAfternoon"
                            :disabled="checkStartTime(form.status)"
                            autocomplete="off"
                            @input="handleUpdateSuggestMedicine"
                          />
                        </div>
                      </el-form-item>
                      <el-form-item
                        class="cs-form m-0"
                        style="flex: 1"
                        label="SL Tối"
                        prop="amountEvening"
                      >
                        <div class="d-flex">
                          <el-input
                            v-model="form.amountEvening"
                            :disabled="checkStartTime(form.status)"
                            autocomplete="off"
                            @input="handleUpdateSuggestMedicine"
                          />
                        </div>
                      </el-form-item>
                      <el-form-item
                        class="mb-0 cs-form"
                        style="flex: 1"
                        label="Đường dùng"
                        :prop="'route_of_use'"
                        :rules="[
                          {
                            required: true,
                            message: 'Trường này không được để trống',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <div class="d-flex">
                          <el-input
                            v-model.number="form.route_of_use"
                            @input="handleUpdateSuggestMedicine"
                            :disabled="checkStartTime(form.status)"
                            autocomplete="off"
                          ></el-input>
                        </div>
                      </el-form-item>
                      <el-form-item
                        class="cs-form m-0"
                        style="flex: 1"
                        label="Đơn vị"
                        prop="amount_unit"
                        :rules="[
                          {
                            required: true,
                            message: 'Trường này không được để trống',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <div class="d-flex">
                          <el-input
                            v-model="form.amount_unit"
                            :disabled="
                              checkStartTime(form.status) ||
                              Boolean(this.form.medicine) ||
                              true
                            "
                            autocomplete="off"
                            @input="handleUpdateSuggestMedicine"
                          />
                        </div>
                      </el-form-item>
                    </div>

                    <div class="row mt-4">
                      <div class="col">
                        <div class>
                          <span class="text-gray-500 text-base"
                            >Hướng dẫn sử dụng thuốc</span
                          >
                          <el-input
                            v-model="form.doctor_note"
                            :disabled="checkStartTime(form.status)"
                            autocomplete="off"
                          ></el-input>
                        </div>
                      </div>
                    </div>
                    <div class="flex justify-end items-center mt-4">
                      <button
                        type="button"
                        class="btn bg-pri text-white text-base h-10 p-2 px-3 fs-16"
                        @click="onMedicineAdd()"
                      >
                        {{ $t(form.isUpdate ? "Cập nhật" : "Thêm mới") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-7 mt-2">
              <div class="p-1 border">
                <el-table
                  ref="tableRef"
                  :data="prescription.person_medicines"
                  style="width: 100%"
                  row-class-name="text-black text-sm"
                  header-cell-class-name="text-black text-base font-semibold"
                  class="cursor-pointer"
                  @row-click="handleEditMedicine"
                >
                  <el-table-column
                    :formatter="
                      (row) => {
                        return row.medicine &&
                          row.medicine.product_info &&
                          row.medicine.product_info.product_code
                          ? `#${row.medicine.product_info.product_code}`
                          : '';
                      }
                    "
                    label="Mã thuốc"
                  ></el-table-column>
                  <el-table-column
                    :formatter="(row) => handleGetMedicineName(row.medicine)"
                    prop="medicine.product_info.name"
                    label="Tên thuốc"
                  ></el-table-column>
                  <el-table-column
                    :formatter="
                      (row) =>
                        row.medicine && row.medicine.product_info
                          ? row.amount_unit ||
                            getUnit(row.medicine.product_info)
                          : ''
                    "
                    label="Đơn vị"
                  ></el-table-column>
                  <el-table-column
                    prop="amount"
                    label="Số lượng"
                  ></el-table-column>
                  <!-- <el-table-column
                  :formatter="(row)=>
                  row.medicine&& row.medicine.product_info? formatPrice(row.medicine.product_info.price_out):''"
                  label="Giá bán"
                  ></el-table-column>-->
                  <!-- <el-table-column
                  :formatter="(row)=>{
                  return row.medicine&&row.medicine.product_info?`${row.medicine.product_info.vat_out||0}%`:''
                  }"
                  label="VAT"
                ></el-table-column>
                <el-table-column
                  :formatter="(row)=>row.medicine?formatExpiredDate(row.medicine.product_info):''"
                  label="Hạn sử dụng"
                  ></el-table-column>-->
                  <el-table-column width="120" label>
                    <template slot-scope="scope">
                      <div class="relative z-10 flex items-center">
                        <i
                          @click="(e) => handleDeleteMedicine(e, scope.row)"
                          class="text-base fs-20 el-icon-delete text-danger block p-2 px-3"
                        ></i>
                        <i
                          @click="handleEditMedicine(scope.row)"
                          class="fs-20 el-icon-edit text-primary block p-2 px-3"
                        ></i>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>

          <!-- WORK -->
        </el-form>
        <div class="flex justify-end mt-4">
          <el-button
            @click="handleOpenModalPrescriptionForm"
            :loading="isLoadingPrescriptionForm"
            :disabled="
              isLoadingPrescriptionForm ||
              !prescription.person_medicines ||
              !prescription.person_medicines.length
            "
            plain
            type="primary"
            >{{ $t("Lưu mẫu") }}</el-button
          >
          <!-- <el-button class="border-0 text-base" @click="handleClose"
          >Huỷ bỏ</el-button
          >-->
          <el-button
            :disabled="
              isLoading ||
              !prescription.person_medicines ||
              !prescription.person_medicines.length
            "
            type="primary"
            @click="onSubmit"
            >{{ $t("Lưu đơn thuốc") }}</el-button
          >
        </div>
      </div>
    </el-dialog>
    <ModalConfirmPrescriptionForm
      ref="ModalConfirmPrescriptionForm"
      @onSuccess="handleSavePrescriptionForm"
    />
  </div>
</template>

<script>
import DocumentSelect from '../../../components/Diseases/DocumentSelect.vue'
import _ from 'lodash'
import moment from 'moment'
import appUtils from '../../../utils/appUtils'
import vSelect from 'vue-select'
import SelectMedicine from './SelectMedicine.vue'
import SelectPrescriptionForm from './SelectPrescriptionForm.vue'
import ModalConfirmPrescriptionForm from './ModalConfirmPrescriptionForm.vue'

const dataDefault = {
  person_id: '',
  name: '',
  person_diseases_id: '',
  amount_unit: '',
  doctor_note: '',
  person_note: '',
  freq_per_day: 1,
  amount_per_one: 1,
  is_monitor: 1,
  amount: 1,
  side_effect: '',
  error: false,
  gap_type: 1,
  status: 0,
  total: 1,
  amountMorning: '',
  amountNoon: '',
  amountAfternoon: '',
  amountEvening: '',
  medicine: null,
  is_by_day: 1,
  isUpdate: false,
  route_of_use: '',
  avail_qty: null,
  qty: null
}

const INVENTORY_EXTERNAL = {
  name: 'Kho tham chiếu',
  id: 0,
  isExternal: true
}

export default {
  name: 'ModalAddPrescription',
  components: {
    DocumentSelect,
    vSelect,
    SelectMedicine,
    SelectPrescriptionForm,
    ModalConfirmPrescriptionForm
  },
  data () {
    return {
      //
      medicines: null,
      selected_person: null,
      loading_medicines: false,
      limit: 1000,
      //
      dialogVisible: false,
      prescription: {
        name: '',
        date: moment(),
        prescribed_doctor: this.$user?.name,
        prescribed_doctor_id: this.$user?.doctor?.id,
        prescribed_clinic: this.$globalClinic?.name,
        person_medicines: [],
        description: ''
      },
      isCreate: false,
      selectedDocs: [],
      selectedMarkDone: [],
      patientVisit: {},
      form: { ...dataDefault },
      clearCount: 0,
      listInventory: [INVENTORY_EXTERNAL],
      inventoryMedicine: null,
      dataDefault,
      isLoading: false,
      isLoadingPrescriptionForm: false,
      clearSelected: 0,
      selectedPrescriptionForm: null,
      refreshPrescriptionForm: 0,
      doctors: []
    }
  },
  created () {
    // this.getPharmas(this.limit)
    this.getInventoryMedicines()
  },
  mounted () {
    this.getDoctors()

    // this.getPharmas(this.limit);
    // this.observerPerson = new IntersectionObserver(this.infiniteScrollMedicine)
  },
  computed: {
    getParamsID () {
      return this.$route?.params?.id
    }
  },
  watch: {
    dialogVisible (stt) {}
  },
  methods: {
    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    selectedMedicine () {
      let self = this
      this.form.amount_unit = self.form.medicine?.unit_name
      this.form.side_effect = self.form.medicine?.side_effect

      if (self.form.medicine.freq_per_day) {
        self.form.medicine.doctor_note = `Sử dụng ${self.form.medicine.freq_per_day} lần/ngày`
        if (self.form.medicine.amount_per_one) {
          self.form.medicine.doctor_note = `${
            self.form.medicine.doctor_note
          }, mỗi lần ${self.form.medicine.amount_per_one} đơn vị ${
            self.form.medicine.amount_unit &&
            '(' + self.form.medicine.amount_unit + ')'
          }`
        }
      }
    },
    onClosePerson () {
      this.observerPerson.disconnect()
    },
    // async onOpenPerson () {
    //   if (this.hasNextPagePerson) {
    //     await this.$nextTick()
    //     this.observerPerson.observe(this.$refs.medicine_list)
    //   }
    // },
    searchMedicine (search) {
      this.search_medicine = search
      // this.getPharmas(this.limit)
    },
    async getPharmas (value) {
      const params = {
        limit: value,
        page: 1
      }
      if (this.search_medicine) {
        params.name = this.search_medicine
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .getPharmasUniversal(params)
        .then((res) => {
          if (res?.data) {
            this.medicines = res.data.map((x) => ({
              ...x,
              isExternal: true,
              label: `${x.active_ingredient}(${x.name}), ${x.unit_name}`
            }))
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    handleClose () {
      this.dialogVisible = false
      this.inventoryMedicine = null
      this.searchMedicine = []
      this.form.medicine = null
      this.form.amount_unit = ''
      this.clearSelected++
      this.selectedPrescriptionForm = null
      this.handleClearData()
      this.$nextTick(() => this.cancel())
    },
    handleMapData (data) {
      return _.cloneDeep({
        ...data,
        person_medicines: (data?.person_medicines || []).map((item) => ({
          ...item,
          medicine: {
            id: item?.pharma_inventory_product_id,
            product_info: item?.pharma_product || {
              name: item?.name,
              amount_unit: item?.amount_unit,
              isExternal: !item?.pharma_inventory_id,
              product_code: item?.medicine?.product_info?.product_code || ''
            }
          }
        }))
      })
    },
    openDialog (obj, currentPatientVisit) {
      this.patientVisit = currentPatientVisit
      this.dialogVisible = true
      this.$nextTick(() => {
        if (obj) {
          this.prescription = this.handleMapData(obj)
          this.inventoryMedicine = this.prescription.pharma_inventory_id
          this.isCreate = false
          if (this.prescription?.person_medicines) {
            for (const item of this.prescription.person_medicines) {
              item.gap_type = item.gap_day > 1 ? 2 : 1
              item.gap_day = item.gap_type === 1 ? 1 : Number(item.gap_day) - 1
              this.calcGapDay(item)
            }
          }
        } else {
          this.isCreate = true
          this.prescription = {
            name: 'Đơn thuốc ' + this.formatDate(moment()),
            date: moment(),
            prescribed_doctor: this.$user?.name,
            prescribed_doctor_id: this.$user?.doctor?.id,
            prescribed_clinic: this.$globalClinic?.name,
            person_medicines: []
          }
        }
        if (!obj?.person_medicines?.length) {
          this.prescription.person_medicines = []
        }
      })
    },
    cancel () {
      this.prescription = {}
      this.prescription.person_medicines = []
      this.$refs.formRef.resetFields()
      this.selectedDocs = []
      this.selectedMarkDone = []
    },
    calcGapDay (medicine) {
      if (this.form.is_by_day) {
        medicine.amountAfternoon = ''
        medicine.amountMorning = ''
        medicine.amountNoon = ''
        medicine.amountEvening = ''
        medicine.total = 1
      } else {
        medicine.amount_per_one = 1
        medicine.freq_per_day = 1
        medicine.total = 1
        // const result = Math.ceil(
        //   medicine.amount / (medicine.amount_per_one * medicine.freq_per_day)
        // )
        // if (!isNaN(result)) {
        //   medicine.total = result
        // }
      }
      this.handleUpdateSuggestMedicine()
    },
    // calcTotal (medicine) {
    //   if (this.form.is_by_day === 1) {
    //     this.form.amount =
    //       this.form.total * this.form.freq_per_day * this.form.amount_per_one
    //   } else {
    //     const result =
    //       Number(medicine.total) *
    //       (Number(medicine.amountMorning) +
    //         Number(medicine.amountNoon) +
    //         Number(medicine.amountAfternoon) +
    //         Number(medicine.amountEvening))

    //     if (!isNaN(result)) {
    //       const totalAmount = [
    //         medicine.amountMorning,
    //         medicine.amountNoon,
    //         medicine.amountAfternoon,
    //         medicine.amountEvening
    //       ].reduce((total, item) => {
    //         return item ? total + 1 : total
    //       }, 0)
    //       medicine.freq_per_day = totalAmount
    //       medicine.amount = Number(Number.parseFloat(result).toFixed(2))
    //     }
    //   }

    //   this.handleUpdateSuggestMedicine()
    // },
    onMedicineDelete (index) {
      if (this.prescription.person_medicines[index].id) {
        this.$set(this.prescription.person_medicines[index], 'deleted', true)
      } else this.prescription.person_medicines.splice(index, 1)
    },
    async handleCheckAvailable (data) {
      let isAvai = true

      if (!Number(data.amount)) {
        this.$message({
          message: this.$t('lbl_amount_required'),
          type: 'warning'
        })
        return
      }

      if (Number(data.qty) < Number(data.amount)) {
        await this.$confirm(
          'Tổng số lượng đang vượt quá số lượng tồn kho, bạn có muốn thêm thuốc vào đơn?',
          this.$t('Cảnh báo'),
          {
            confirmButtonText: 'Xác nhận',
            cancelButtonText: 'Huỷ',
            type: 'warning'
          }
        )
          .then(() => {
            isAvai = true
          })
          .catch(() => {
            isAvai = false
          })
      }
      return isAvai
    },
    onMedicineAdd () {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) return
        if (valid) {
          if (!this.form?.medicine?.id) {
            this.$message({
              message: 'Vui lòng chọn danh mục thuốc',
              type: 'warning'
            })
            return
          }
          const isAvai = await this.handleCheckAvailable(this.form)
          if (!isAvai) return

          const newForm = {
            ...this.form,
            time1: Number(this.form.amountMorning) || null,
            time2: Number(this.form.amountNoon) || null,
            time3: Number(this.form.amountAfternoon) || null,
            time4: Number(this.form.amountEvening) || null,
            pharma_product_id: this.form.medicine?.product_id,
            pharma_inventory_id: this.form.medicine?.inventory_id,
            pharma_inventory_product_id: this.form.medicine?.id,
            active_ingredient:
              this.form.medicine?.product_info?.active_ingredient_info?.name ||
              this.form.medicine?.active_ingredient,
            quality:
              this.form.medicine?.product_info?.quality ||
              this.form.medicine?.concentration
          }

          if (newForm.isUpdate) {
            this.prescription.person_medicines =
              this.prescription.person_medicines.map((item) => {
                if (item?.medicine?.id === newForm?.medicine?.id) {
                  return { ...item, ...newForm }
                } else {
                  return { ...item }
                }
              })
          } else {
            const medicineInfo = this.prescription.person_medicines?.find(
              (item) => {
                if (item?.medicine?.id === newForm?.medicine?.id) {
                  return true
                } else {
                  return false
                }
              }
            )
            if (medicineInfo) {
              this.prescription.person_medicines =
                this.prescription.person_medicines.map((item) => {
                  if (item?.medicine?.id === newForm?.medicine?.id) {
                    return { ...item, ...newForm }
                  } else {
                    return { ...item }
                  }
                })
            } else {
              this.prescription.person_medicines.push({
                ...newForm,
                isUpdate: false
              })
            }
          }

          this.form = { ...dataDefault }
          this.clearCount++
        }
      })
    },
    onSubmit () {
      this.prescription.person_medicines.forEach((x, index) => {
        // const realName = x.name.Product_Name
        // {{ option.active_ingredient }}({{ option.name }}), {{ option.unit_name }}
        // const realName = `${x.medicine.active_ingredient}(${}), ${x.medicine.unit_name}`
        this.prescription.person_medicines[index].name =
          x.medicine.product_info.name
      })
      if (this.isCreate) {
        this.createPrescription()
      } else this.updatePrescription()
    },
    async createPrescription () {
      try {
        this.isLoading = true
        if (this.prescription?.person_medicines) {
          for (let item of this.prescription.person_medicines) {
            item.gap_day = item.gap_type === 1 ? 1 : Number(item.gap_day) + 1
            item = this.cleanObject(item)
          }
        }
        const params = {
          person_id: this.patientVisit.person.id,
          patient_visit_id: this.patientVisit.id,
          ...this.prescription,
          prescribed_doctor: this.$user?.name,
          prescribed_doctor_id: this.$user?.doctor?.id,
          document_ids: this.selectedDocs
        }
        if (this.selectedMarkDone.length) {
          params.make_done_document_ids = this.selectedMarkDone
        }

        if (this.inventoryMedicine) {
          params.pharma_inventory_id = this.inventoryMedicine
        }

        await this.$rf
          .getRequest('DoctorRequest')
          .createPrescriptionV2(params)
          .then(async (res) => {
            this.$message({
              message: 'Thêm mới thành công',
              type: 'success'
            })
            this.handleClose()
            this.$emit('refresh', res.data.id, true)
          })
      } catch (e) {
        this.$message({
          message: 'Thêm mới thất bại!',
          type: 'warning'
        })
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },
    // async handleModifyAvailable () {
    //   if (
    //     this.prescription.person_medicines?.some(
    //       item => item?.medicine?.product_info?.isExternal
    //     )
    //   ) {
    //     return
    //   }

    //   const rawMedicines = this.prescription.person_medicines?.map(item => ({
    //     amount: item?.amount,
    //     id: item?.medicine?.id
    //   }))

    //   const params = {
    //     product_invent_ids: rawMedicines,
    //     prescriptionId: this.prescription?.id
    //   }

    //   try {
    //     await this.$rf
    //       .getRequest('DoctorRequest')
    //       .postHandleUpdateAvailable(params)
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },
    async updatePrescription () {
      try {
        // await this.handleModifyAvailable()
        this.isLoading = true
        if (this.prescription?.person_medicines) {
          for (let item of this.prescription.person_medicines) {
            item.gap_day = item.gap_type === 1 ? 1 : Number(item.gap_day) + 1
            item = this.cleanObject(item)
          }
        }
        const params = {
          person_id: this.patientVisit.person.id,
          patient_visit_id: this.patientVisit.id,
          ...this.prescription,
          prescribed_doctor: this.$user?.name,
          prescribed_doctor_id: this.$user?.doctor?.id,
          document_ids: this.selectedDocs
        }
        if (this.selectedMarkDone.length) {
          params.make_done_document_ids = this.selectedMarkDone
        }

        if (this.prescription?.pharma_inventory_id) {
          params.pharma_inventory_id = this.inventoryMedicine
        }

        await this.$rf
          .getRequest('DoctorRequest')
          .updatePrescriptionV2(this.prescription.id, params)
          .then((res) => {
            if (res?.data) {
              this.$message({
                message: 'Cập nhật dữ liệu thành công',
                type: 'success'
              })
              this.$emit('refresh', this.prescription.id, true)
              this.handleClose()
            }
          })
      } catch (e) {
        this.$message({
          message: 'Cập nhật dữ liệu thất bại!',
          type: 'warning'
        })
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },
    checkStartTime (status) {
      return status !== 0
    },
    isImage (file_name) {
      return file_name.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null
    },
    formatDateDMY (date) {
      return moment(date).format('HH:mm DD/MM/YYYY')
    },
    formatDate (date) {
      return moment(date).format('DD/MM/YYYY')
    },
    getLinkDownload (link) {
      return appUtils.getDocumentURL(link)
    },
    handlerMarkDone (event) {
      this.selectedMarkDone = event
    },
    handleDeleteMedicine (e, medicine) {
      e.stopPropagation()

      this.prescription.person_medicines =
        this.prescription.person_medicines.filter(
          (item) => item?.pharma_product_id !== medicine?.pharma_product_id
        )
    },
    handleEditMedicine (medicine) {
      const morning = medicine.time1 || medicine?.morning
      const lunch = medicine.time2 || medicine?.lunch
      const affternoon = medicine.time3 || medicine?.affternoon
      const evening = medicine.time4 || medicine?.evening

      this.form = {
        ...medicine,
        amountMorning: morning,
        amountNoon: lunch,
        amountAfternoon: affternoon,
        amountEvening: evening,
        isUpdate: true,
        is_by_day: medicine.is_by_day || 1,
        total:
          Number(medicine.amount) /
          (Number(morning) +
            Number(lunch) +
            Number(affternoon) +
            Number(evening) || Number(medicine.freq_per_day))
      }
      this.inventoryMedicine = this.prescription?.pharma_inventory_id || 0
    },
    handleUpdateMedicine (medicine) {
      this.handleClearData()
      this.form.medicine = medicine
      this.form.amount_unit = medicine.product_info?.isExternal
        ? medicine.product_info?.unit_id
        : this.getUnit(medicine.product_info)
      this.form.avail_qty = medicine.avail_qty
      this.form.qty = medicine.qty

      this.form.route_of_use = this.handleGetRouteOfUse(medicine?.product_info)
      this.handleUpdateSuggestMedicine()
    },
    handleClearData () {
      this.form.amountAfternoon = ''
      this.form.amountMorning = ''
      this.form.amountNoon = ''
      this.form.amountEvening = ''
      this.form.amount_per_one = 1
      this.form.freq_per_day = 1
      this.form.total = 1
      this.form.amount = 1
      this.form.route_of_use = ''
      this.form.amount_unit = ''
      this.form.doctor_note = ''
      this.form = { ...dataDefault }
    },
    handleGetRouteOfUse (data) {
      return data?.use_route_info?.name || ''
    },
    handleUpdateSuggestMedicine () {
      if (this.form.is_by_day === 1) {
        if (this.form.freq_per_day) {
          this.form.doctor_note = `${this.form.route_of_use || ''} ${
            this.form.freq_per_day
          } lần/ngày`
          if (this.form.amount_per_one) {
            this.form.doctor_note = `${this.form.doctor_note}, mỗi lần ${
              this.form.amount_per_one
            } ${this.form.amount_unit && '(' + this.form.amount_unit + ')'}`
          }
        }
      } else {
        const amountMorning = this.form.amountMorning
        const amountNoon = this.form.amountNoon
        const amountAfternoon = this.form.amountAfternoon
        const amountEvening = this.form.amountEvening
        const unit = this.form.amount_unit

        if (
          [amountMorning, amountNoon, amountAfternoon, amountEvening].every(
            (item) => !item
          ) ||
          !unit
        ) {
          this.form.doctor_note = ''
          return
        }

        const morningLabel = amountMorning
          ? `${amountMorning} (${unit}) buổi sáng`
          : ''
        const noonLabel = amountNoon
          ? `,${amountNoon} (${unit}) buổi trưa`
          : ''
        const afternoonLabel = amountAfternoon
          ? `,${amountAfternoon} (${unit}) buổi chiều,`
          : ''
        const evevningLabel = amountEvening
          ? `,${amountEvening} (${unit}) buổi tối`
          : ''

        this.form.doctor_note =
          `${this.form.route_of_use} ${morningLabel} ${noonLabel} ${afternoonLabel} ${evevningLabel}`.trim()
      }
    },
    getUnit (data) {
      return data?.unit_info?.name || ''
    },

    formatPrice (price) {
      return price ? appUtils.numberFormat(Number(price), 'VNĐ') : ''
    },
    formatExpiredDate (data) {
      const date =
        Number(data?.expiry_date_day) && Number(data?.expiry_date_day) < 10
          ? `0${data?.expiry_date_day}/`
          : data?.expiry_date_day
            ? `${data?.expiry_date_day}/`
            : ''

      const month =
        Number(data?.expiry_date_month) && Number(data?.expiry_date_month) < 10
          ? `0${data?.expiry_date_month}/`
          : data?.expiry_date_month
            ? `${data?.expiry_date_month}/`
            : ''
      return `${date}${month}${data?.expiry_date_year || ''}`
    },
    async getInventoryMedicines (search) {
      try {
        const params = {
          org_id: this.$globalClinic.org_id,
          page_num: 1,
          page_size: 1000,
          keyword: search,
          ws_id: this.$globalClinic.id
        }

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getInventoryMedicines(params)
        const responseData = response.data.data || []
        responseData.push(INVENTORY_EXTERNAL)
        this.listInventory = responseData
      } catch (error) {
        console.log(error)
      }
    },
    handleChangeInventory () {
      this.prescription.person_medicines = []
      this.clearCount++
      this.prescription.pharma_inventory_id = this.inventoryMedicine || 0
    },
    handleSelectedIsByDay () {
      this.form.amount_per_one = 1
      this.form.freq_per_day = 1
      this.form.total = 1
      this.form.amount = 1
      this.handleUpdateSuggestMedicine()
    },
    handleSelectedIsBySection () {
      this.form.amountAfternoon = ''
      this.form.amountNoon = ''
      this.form.amountMorning = ''
      this.form.amountEvening = ''
      this.form.total = 1
      this.form.amount = 1
      this.handleUpdateSuggestMedicine()
    },
    cleanObject (obj) {
      for (let key in obj) {
        if (!obj[key]) {
          delete obj[key]
        }
      }
      return obj
    },
    handleGetMedicineName (medicine) {
      const medicineName = medicine?.name || medicine?.product_info?.name || ''
      const activeIngredient =
        medicine?.active_ingredient ||
        medicine?.product_info?.active_ingredient_info?.name ||
        ''
      const quality =
        medicine?.concentration || medicine?.product_info?.quality || ''

      return `${activeIngredient} ${quality} ${
        medicineName ? `(${medicineName})` : ''
      }`
    },

    handleChangePrescriptionForm (data) {
      if (!data.id) {
        this.inventoryMedicine = null
        this.searchMedicine = []
        this.form.medicine = null
        this.form.amount_unit = ''
        this.clearSelected++
        this.clearCount++
        this.prescription = {
          name: 'Đơn thuốc ' + this.formatDate(moment()),
          date: moment(),
          prescribed_doctor: this.$user?.name,
          prescribed_doctor_id: this.$user?.doctor?.id,
          prescribed_clinic: this.$globalClinic?.name,
          person_medicines: [],
          description: ''
        }
        this.selectedPrescriptionForm = null
        this.handleClearData()
        return
      }

      this.inventoryMedicine = null
      this.form = { ...this.dataDefault }

      const newData = { ...data }
      this.selectedPrescriptionForm = newData
      this.prescription = {
        ...this.prescription,
        name: newData?.name || '',
        date: newData?.date || new Date(),
        description: newData?.description || '',
        person_medicines: (newData?.prescription_form_medicines || []).map(
          (item) => ({
            ...item,
            medicine: {
              id: item?.pharma_inventory_product_id,
              product_info: item?.pharma_product || {
                name: item?.name,
                amount_unit: item?.amount_unit,
                isExternal: !item?.pharma_inventory_id,
                product_code: item?.medicine_code
              }
            }
          })
        )
      }

      this.inventoryMedicine = newData?.pharma_inventory_id
      if (this.prescription?.person_medicines) {
        for (const item of this.prescription.person_medicines) {
          item.gap_type = item.gap_day > 1 ? 2 : 1
          item.gap_day = item.gap_type === 1 ? 1 : Number(item.gap_day) - 1
          this.calcGapDay(item)
        }
      }
    },
    handleOpenModalPrescriptionForm () {
      this.$refs.ModalConfirmPrescriptionForm.handleOpen(
        { ...this.prescription, inventoryId: this.inventoryMedicine },
        this.selectedPrescriptionForm
      )
    },
    handleSavePrescriptionForm () {
      this.refreshPrescriptionForm++
    },
    async getDoctors () {
      try {
        const clinic_id = this.$globalClinic?.id
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .getDoctorsOfWorkspace(clinic_id, { limit: 1000 })
        this.doctors = res.data?.data
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-input-group__append,
  .el-input-group__prepend {
    border: none;
  }
  input {
    text-align: inherit !important;
  }
  .el-input-number {
    width: 100%;
  }
}
.select-box {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #f4f4f4;
  border-radius: 5px;
}

::v-deep {
  .cs-form {
    label {
      line-height: 16px !important;
    }
  }
}

::v-deep {
  .cs-dialog {
    .el-dialog__header {
      padding: 0 !important;
    }
  }
}
</style>
