<template>
  <div>
    <el-dialog
      title="Mẫu đơn thuốc"
      :visible.sync="isOpen"
      width="60%"
      :before-close="handleClose"
    >
      <template slot="title">
        <div class="fs-20 font-bold uppercase">
          {{ $t("Mẫu đơn thuốc") }}
        </div>
      </template>
      <div class="text-black">
        <div>
          <div>{{ $t("Tên mẫu đơn thuốc") }}</div>
          <el-input
            placeholder="Nhập tên mẫu đơn thuốc"
            v-model="prescriptionFormName"
          ></el-input>
        </div>

        <div
          class="flex flex-col md:flex-row items-center gap-2 justify-end mt-4"
        >
          <el-button plain type="primary" @click="handleClose">{{
            $t("Huỷ")
          }}</el-button>
          <el-button
            v-if="prescriptionForm && prescriptionForm.id"
            :disabled="isLoading"
            type="primary"
            @click="handleSubmit(false)"
            >{{ $t("Cập nhật") }}</el-button
          >
          <el-button
            :disabled="isLoading"
            type="primary"
            @click="handleSubmit(true)"
            >{{ $t("Tạo mới") }}</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>
import appUtils from '../../../utils/appUtils'
export default {
  name: 'ModalConfirmPrescriptionForm',
  components: {},
  data () {
    return {
      isOpen: false,
      prescription: {},
      prescriptionForm: null,
      isLoading: false,
      prescriptionFormName: ''
    }
  },
  methods: {
    handleClose () {
      this.isOpen = false
      this.prescription = {}
      this.prescriptionForm = null
    },
    handleOpen (prescriptionData, selectedPrescriptionForm) {
      this.isOpen = true
      this.prescriptionForm = selectedPrescriptionForm
      this.prescription = prescriptionData
      this.prescriptionFormName = this.prescription?.name || ''
    },
    handleChangeMethod (value) {
      this.paymentMethod = value || 1
    },
    async requestPaymentMultipleSlips () {
      try {
      } catch (error) {
        console.log(error)
      }
    },
    async handleUpdate (params) {
      if (!this.prescriptionForm?.id) return

      try {
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .updatePrescriptionForm(this.prescriptionForm.id, params)

        if (response.status === 200) {
          this.$message({
            message: 'Cập nhật mẫu đơn thuốc thành công',
            type: 'success'
          })
          this.handleClose()
          this.$emit('onSuccess')
        }
      } catch (error) {
        console.log(error)
        this.$message({
          message: 'Cập nhật mẫu đơn thuốc thất bại',
          type: 'error'
        })
      } finally {
        this.isLoading = false
      }
    },
    handleSubmit (isCreate) {
      this.isLoading = true
      const medicines = this.prescription?.person_medicines || []
      if (medicines) {
        for (let item of medicines) {
          item.gap_day = item.gap_type === 1 ? 1 : Number(item.gap_day) + 1
          item = appUtils.cleanObject(item)
        }
      }
      const params = {
        name: this.prescriptionFormName || this.prescription?.name,
        date: this.prescription?.date,
        prescribed_doctor: this.prescription?.prescribed_doctor,
        prescribed_clinic: this.prescription?.prescribed_clinic,
        ws_id: this.$globalClinic?.id,
        prescription_form_medicines: medicines?.map((item) => ({
          ...item,
          medicine_code: item?.medicine?.product_info?.product_code
        })),
        description: this.prescription?.description
      }

      if (this.prescription?.inventoryId) {
        params.pharma_inventory_id = this.prescription?.inventoryId
      }

      if (isCreate) {
        this.handleCreatePrescriptionForm(params)
      } else {
        this.handleUpdate(params)
      }
    },
    async handleCreatePrescriptionForm (params) {
      try {
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .createPrescriptionForm(params)

        if (response.status === 200) {
          this.$message({
            message: 'Lưu mẫu đơn thuốc thành công',
            type: 'success'
          })
          this.handleClose()

          this.$emit('onSuccess')
        }
      } catch (error) {
        console.log(error)
        this.$message({
          message: 'Lưu mẫu đơn thuốc thất bại',
          type: 'error'
        })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
  
  <style lang="scss" scoped>
.cs-text {
  text-transform: lowercase;

  p::first-letter {
    text-transform: capitalize;
  }
}
</style>