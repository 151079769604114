<template>
  <div class="mb-2">
    <div class="mb-1">{{ $t("Mẫu đơn thuốc") }}</div>
    <el-select
      class="w-full"
      v-model="selectedPrescriptionForm"
      filterable
      remote
      reserve-keyword
      clearable
      :remote-method="getPrescriptionForm"
      :placeholder="$t('Chọn mẫu đơn thuốc')"
      @change="handleSelectPrescriptionForm"
      value-key="id"
    >
      <el-option
        v-for="item in prescriptionForms"
        :key="item.id"
        :label="item.name"
        :value="item"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'SelectPrescriptionForm',
  props: {
    clearSelected: Number,
    refreshPrescriptionForm: Number
  },
  data () {
    return {
      prescriptionForms: [],
      selectedPrescriptionForm: null
    }
  },
  watch: {
    $globalClinic () {
      this.getPrescriptionForm()
    },
    clearSelected () {
      this.selectedPrescriptionForm = null
    },
    refreshPrescriptionForm () {
      this.getPrescriptionForm()
    }
  },
  created () {
    this.getPrescriptionForm()
  },
  methods: {
    async getPrescriptionForm (searchValue) {
      if (!this.$globalClinic?.id) return

      try {
        const params = {
          page_num: 1,
          page_size: 10000,
          sort_by: 'created_at',
          order: 'desc',
          ws_id: this.$globalClinic?.id,
          keyword: searchValue
        }
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getPrescriptionForms(params)

        this.prescriptionForms = response.data?.data || []
      } catch (error) {
        console.log(error)
      }
    },
    handleDebounce: debounce(function (value) {
      this.getPrescriptionForm(value)
    }, 500),
    handleSelectPrescriptionForm (data) {
      this.$emit('onSelect', data)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>