<template>
  <div class="rounded-lg p-3 shadow-2xl bg-white mb-2">
    <div class="flex">
      <div class="fs-20 fw-500 ml-1">
        {{ $t("schedule_page.lbl_prescription") }}
      </div>
      <div
        @click="
          patientVisit.status !== EXAMINATION_STATUS.CODE.CANCEL
            ? createPrescription()
            : () => {}
        "
        class="cursor-pointer ml-auto p-2 w-36 rounded-lg border border-primary flex justify-center items-center"
      >
        <img
          src="@public/assets/images/icon/patientVisit/plus-circle.svg"
          alt
        />
        <p class="ml-2 text-primary font-weight-bold mb-0">
          {{ $t("schedule_page.lbl_Prescriptions") }}
        </p>
      </div>
    </div>

    <div class="mt-2">
      <div
        class="mb-2"
        v-for="item in patientVisit.prescription"
        :key="item.id"
      >
        <div @click="viewDetail(item)" class="mb-1 flex">
          <div class="inline-block" role="button">
            <div
              class="flex items-center font-medium txt-pri hover:text-blue-600 transition-all duration-200"
            >
              <span class="mr-1">{{ item.name }}</span>
            </div>
            <div class="text-xs italic">
              <span class="mr-1">{{ item.sign ? "Đã ký:" : "Đã lưu:" }}</span>
              <span>{{ getSignAt(item) }}</span>
            </div>
          </div>
          <div class="ml-auto" :set="(status = getApptStatus(item.sign))">
            <div :class="status.class" class="border rounded-full">
              <div class="py-1 px-2">{{ status.text }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalAddPrescription
      ref="ModalAddPrescription"
      @refresh="refreshAndOpenPreview"
    />
    <ModalViewPrescription
      ref="ModalViewPrescription"
      :patient-visit="patientVisit"
      @refresh="getPersonMedicine"
      :disease="disease"
    />
    <PdfViewer
      ref="PdfViewer"
      containerIDProps="ContainerPrescriptionPDFViewerID"
      elementIDProps="PrescriptionPDFViewerID"
    />
  </div>
</template>
<script>
import appUtils from '../../utils/appUtils'
import {
  ENVELOPE_DOCUMENT_TYPE,
  EXAMINATION_STATUS
} from '../../utils/constants'
import PdfViewer from '../PdfViewer/PdfViewer.vue'
import ModalAddPrescription from './Prescription/ModalAdd.vue'
import ModalViewPrescription from './Prescription/ModalView.vue'

export default {
  name: 'Prescription',
  components: {
    ModalAddPrescription,
    ModalViewPrescription,
    PdfViewer
  },
  props: {
    patientVisit: {
      type: Object,
      required: true
    },
    disease: {
      type: Object
    }
  },
  data () {
    return {
      appUtils,
      EXAMINATION_STATUS
    }
  },
  methods: {
    getApptStatus (stt) {
      if (stt) {
        return {
          class: 'text-green-600 border-green-600 bg-green-50',
          text: 'Đã ký'
        }
      } else {
        return {
          class: 'text-red-600 border-red-600 bg-red-50',
          text: 'Chưa ký'
        }
      }
    },
    refreshAndOpenPreview (idPrescription, triggerCreateEnvelope) {
      this.$emit('refresh')
      this.$refs.ModalViewPrescription.openDialog(
        idPrescription,
        triggerCreateEnvelope
      )
    },
    createPrescription () {
      this.$refs.ModalAddPrescription.openDialog(null, this.patientVisit)
    },
    getPersonMedicine () {
      this.$emit('refresh')
    },
    formatDateDMY (date_time) {
      return date_time && window.moment(date_time).format('DD/MM/YYYY')
    },
    getTime (date_time) {
      return window.moment(date_time).format('HH:mm:ss')
    },
    viewDetail (prescription) {
      if (prescription?.document_link && prescription?.sign?.id) {
        this.$refs.PdfViewer.handleOpen(
          prescription?.document_link,
          ENVELOPE_DOCUMENT_TYPE.PRESCRIPTION,
          prescription.id
        )
      } else {
        this.$refs.ModalViewPrescription.openDialog(prescription.id)
      }
    },
    formatDate (date) {
      return window.moment(date).format('HH:mm:ss-DD/MM/YYYY')
    },
    getSignAt (item) {
      let time

      if (item.sign) {
        time = window
          .moment(item.sign?.sign_at)
          .utc()
          .format('HH:mm:ss DD/MM/YYYY')
      } else {
        time = window
          .moment(item.updated_at)
          .utc()
          .format('HH:mm:ss DD/MM/YYYY')
      }

      return time
      // return appUtils.formatDateTimeFull(time)
    }
  }
}
</script>
