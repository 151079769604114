import { render, staticRenderFns } from "./SelectMedicine.vue?vue&type=template&id=56fc01d2&scoped=true"
import script from "./SelectMedicine.vue?vue&type=script&lang=js"
export * from "./SelectMedicine.vue?vue&type=script&lang=js"
import style0 from "./SelectMedicine.vue?vue&type=style&index=0&id=56fc01d2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56fc01d2",
  null
  
)

export default component.exports