<template>
  <div>
    <span class>Chọn danh mục Thuốc</span>
    <div class="cs-relative">
      <div class="cs-relative" v-click-outside="handleClose">
        <el-input
          class="cs-input"
          v-model="searchQuery"
          @input="handleSearch"
          autocomplete="off"
          placeholder="Nhập mã, tên danh mục thuốc"
          ref="inputRef"
          id="inputRef"
          clearable
          :disabled="!inventoryInfo"
        ></el-input>
        <!-- <b-form-input
          v-model="searchQuery"
          @update="handleSearch"
          @click="handleClick"
          autocomplete="off"
          placeholder="Nhập mã, tên danh mục thuốc"
          ref="inputRef"
        />-->
        <!-- <div class="cs_down_icon">
          <div class="d-flex cursor-pointer" style="gap: 10px">
            <svg
              @click="handleClearSelected"
              v-if="searchQuery"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mt-1"
            >
              <path
                d="M13.4099 11.9999L19.7099 5.70994C19.8982 5.52164 20.004 5.26624 20.004 4.99994C20.004 4.73364 19.8982 4.47825 19.7099 4.28994C19.5216 4.10164 19.2662 3.99585 18.9999 3.99585C18.7336 3.99585 18.4782 4.10164 18.2899 4.28994L11.9999 10.5899L5.70994 4.28994C5.52164 4.10164 5.26624 3.99585 4.99994 3.99585C4.73364 3.99585 4.47824 4.10164 4.28994 4.28994C4.10164 4.47825 3.99585 4.73364 3.99585 4.99994C3.99585 5.26624 4.10164 5.52164 4.28994 5.70994L10.5899 11.9999L4.28994 18.2899C4.19621 18.3829 4.12182 18.4935 4.07105 18.6154C4.02028 18.7372 3.99414 18.8679 3.99414 18.9999C3.99414 19.132 4.02028 19.2627 4.07105 19.3845C4.12182 19.5064 4.19621 19.617 4.28994 19.7099C4.3829 19.8037 4.4935 19.8781 4.61536 19.9288C4.73722 19.9796 4.86793 20.0057 4.99994 20.0057C5.13195 20.0057 5.26266 19.9796 5.38452 19.9288C5.50638 19.8781 5.61698 19.8037 5.70994 19.7099L11.9999 13.4099L18.2899 19.7099C18.3829 19.8037 18.4935 19.8781 18.6154 19.9288C18.7372 19.9796 18.8679 20.0057 18.9999 20.0057C19.132 20.0057 19.2627 19.9796 19.3845 19.9288C19.5064 19.8781 19.617 19.8037 19.7099 19.7099C19.8037 19.617 19.8781 19.5064 19.9288 19.3845C19.9796 19.2627 20.0057 19.132 20.0057 18.9999C20.0057 18.8679 19.9796 18.7372 19.9288 18.6154C19.8781 18.4935 19.8037 18.3829 19.7099 18.2899L13.4099 11.9999Z"
                fill="black"
              />
            </svg>

            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.9999 9.16994C16.8126 8.98369 16.5591 8.87915 16.2949 8.87915C16.0308 8.87915 15.7773 8.98369 15.5899 9.16994L11.9999 12.7099L8.45995 9.16994C8.27259 8.98369 8.01913 8.87915 7.75495 8.87915C7.49076 8.87915 7.23731 8.98369 7.04995 9.16994C6.95622 9.26291 6.88183 9.37351 6.83106 9.49537C6.78029 9.61723 6.75415 9.74793 6.75415 9.87994C6.75415 10.012 6.78029 10.1427 6.83106 10.2645C6.88183 10.3864 6.95622 10.497 7.04995 10.5899L11.2899 14.8299C11.3829 14.9237 11.4935 14.9981 11.6154 15.0488C11.7372 15.0996 11.8679 15.1257 11.9999 15.1257C12.132 15.1257 12.2627 15.0996 12.3845 15.0488C12.5064 14.9981 12.617 14.9237 12.7099 14.8299L16.9999 10.5899C17.0937 10.497 17.1681 10.3864 17.2188 10.2645C17.2696 10.1427 17.2957 10.012 17.2957 9.87994C17.2957 9.74793 17.2696 9.61723 17.2188 9.49537C17.1681 9.37351 17.0937 9.26291 16.9999 9.16994Z"
                fill="black"
              />
            </svg>
          </div>
        </div>-->
      </div>
      <div v-show="isShowOptions" class="cs-table">
        <div class="table-header-container">
          <div class="row cs-header">
            <div class="col-2 cs-border-right cs-header-item">Mã thuốc</div>
            <div class="col-3 cs-border-right cs-header-item">Tên thuốc</div>
            <div class="col-2 cs-border-right cs-header-item">Hàm lượng</div>
            <div class="col-3 cs-border-right cs-header-item">Hoạt chất</div>
            <div class="col-2 cs-header-item">Đơn vị</div>
          </div>
        </div>
        <div class="table-container" v-loading="isLoading">
          <div class="cs-body" id="tableID">
            <div
              v-for="item in medicines"
              :key="item.id"
              @click="handleSelectedItem(item)"
              class="row cs-option"
              :set="(medicineInfo = item.product_info)"
            >
              <div class="col-2 cs-border-right cs-item">{{ `#${medicineInfo.product_code}` }}</div>
              <div class="col-3 cs-border-right cs-item">{{ medicineInfo.name }}</div>
              <div class="col-2 cs-border-right cs-item">{{ medicineInfo.quality }}</div>
              <div class="col-3 cs-border-right cs-item">{{ getActiveIngredient(medicineInfo) }}</div>
              <div class="col-2 cs-item">{{ getUnit(medicineInfo) }}</div>
            </div>
            <div
              class="flex items-center justify-center py-3"
              v-if="medicines.length === 0 && !isLoading"
            >
              <span>Không có dữ liệu</span>
            </div>
          </div>
          <!-- <div class="flex items-center justify-center py-3" v-if="isLoading">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '@/utils/appUtils'
import { debounce } from 'lodash'

export default {
  name: 'SelectMedicine',
  props: {
    medicine: Object,
    inventoryMedicine: Number,
    clearCount: Number,
    listInventory: Array
  },
  data () {
    return {
      isShowOptions: false,
      medicines: [],
      currentPage: 1,
      totalPage: 0,
      searchQuery: null,
      limit: 10,
      isLoading: false
    }
  },
  computed: {
    inventoryInfo () {
      this.isShowOptions = true
      this.medicines = []
      return this.listInventory?.find(
        item => item?.id === this.inventoryMedicine
      )
    }
  },
  watch: {
    isShowOptions: {
      handler: function (isShow) {
        if (isShow === true) {
          const table = document.querySelector('#tableID')
          if (table) {
            table.addEventListener('scroll', e => {
              if (
                table.scrollTop + table.clientHeight >=
                  table.scrollHeight - 10 &&
                !this.isLoading
              ) {
                const nextPage = this.currentPage + 1

                if (nextPage <= this.totalPage) {
                  this.currentPage = nextPage
                  this.handleGetMedicines(true)
                }
              }
            })
          }
        }
      },
      deep: true
    },
    clearCount () {
      this.handleClearSelected()
    },
    medicine: {
      deep: true,
      handler (data) {
        this.isShowOptions = true
        if (data) this.searchQuery = this.handleGetMedicineName(data)
        else this.searchQuery = ''
        // data?.product_info?.name
      }
    },
    inventoryMedicine (value) {
      this.currentPage = 1
      this.limit = 10
      this.isShowOptions = true
      this.handleGetMedicines()
    },
    searchQuery () {
      this.isShowOptions = true
    }
  },

  created () {
    // this.handleGetMedicines()
  },
  mounted () {
    if (this.medicine?.product_info?.id) {
      this.searchQuery = this.handleGetMedicineName(this.medicine)
      // this.medicine?.product_info?.name
    }
    const input = document.getElementById('inputRef')
    input.addEventListener('click', () => {
      this.isShowOptions = !this.isShowOptions
    })
  },
  methods: {
    handleClose () {
      this.isShowOptions = false
    },
    handleSearch (value) {
      this.searchQuery = value
      this.currentPage = 1

      this.handleDebounce()
    },
    handleGetMedicines (isPush) {
      console.log({ search: this.searchQuery })
      if (this.inventoryInfo?.isExternal) {
        this.getPharmasExternal(isPush)
      } else {
        this.fetchPharmaProducts(isPush)
      }
    },
    handleDebounce: debounce(function () {
      this.handleGetMedicines()
    }, 100),
    handleSelectedItem (item) {
      const hasData = this.medicine?.id === item?.id

      if (hasData) return
      this.searchQuery = this.handleGetMedicineName(item)

      // item.product_info.name
      this.$emit('onUpdateMedicine', item)
    },
    handleClearSelected () {
      console.log('clear')
      // e && e.stopPropagation()
      this.searchQuery = null
      this.$refs.inputRef.nodeValue = ''
      this.$emit('onClearMedicine')
      // this.$refs.inputRef.focus()
      this.isShowOptions = true
      this.handleGetMedicines()
    },
    getActiveIngredient (product) {
      return product?.isExternal
        ? product?.active_ingredient || ''
        : product?.active_ingredient_info?.name || ''
    },
    getUnit (product) {
      return product?.isExternal
        ? product?.unit_id
        : product?.unit_info?.name || ''
    },
    formatPrice (price) {
      return appUtils.numberFormat(price, 'VNĐ')
    },
    async fetchPharmaProducts (isPush) {
      if (!this.inventoryInfo) return
      // this.medicines = []
      this.isLoading = true

      try {
        const inventoryId = this.inventoryInfo.id

        if (!inventoryId) return
        const params = {
          id: inventoryId,
          keyword: this.searchQuery,
          page_num: this.currentPage,
          page_size: this.limit,
          sort_by: 'id',
          order: 'desc'
        }
        // TODO: Update after

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getMedicines(inventoryId, params)
        if (isPush) {
          const oldData = [...this.medicines]
          this.medicines = [...oldData, ...(response.data?.data || [])]
          this.totalPage = response.data?.page?.total_page
        } else {
          this.medicines = response.data?.data || []
          this.totalPage = response.data?.page?.total_page
        }
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        console.log(error)
      }
    },
    async getPharmasExternal (isPush) {
      // this.medicines = []
      this.isLoading = true
      const params = {
        limit: this.limit,
        page: this.currentPage
      }
      if (this.searchQuery) {
        params.name = this.searchQuery
      }
      // {{ option.active_ingredient }}({{ option.name }}), {{ option.unit_name }}
      await this.$rf
        .getRequest('DoctorRequest')
        .getPharmasUniversal(params)
        .then(res => {
          let mapData = []
          if (res?.data) {
            if (isPush) {
              const newData = res.data.map(x => ({
                ...x,
                label: `${x.active_ingredient}(${x.name}), ${x.unit_name}`
              }))
              const oldData = [...this.medicines]
              mapData = [...oldData, ...(newData || [])]
            } else {
              mapData = res.data || []
            }

            this.medicines = mapData?.map(item => {
              return {
                ...item,
                product_info: {
                  product_code: item?.national_pharma_code,
                  name: item?.name,
                  quality:
                    item?.concentration === 'NULL' ? '' : item?.concentration,
                  active_ingredient: item?.active_ingredient,
                  unit_id: item?.unit_name,
                  isExternal: true
                }
              }
            })
            this.totalPage = res?.last_page || 0
            console.log(this.totalPage)
            this.isLoading = false
          }
        })
        .catch(e => {
          this.isLoading = false
          console.log(e)
        })
    },
    handleGetMedicineName (medicine) {
      const medicineName = medicine?.name || medicine?.product_info?.name || ''
      const activeIngredient =
        medicine?.active_ingredient ||
        medicine?.product_info?.active_ingredient_info?.name ||
        ''
      const quality =
        medicine?.concentration || medicine?.product_info?.quality || ''

      return `${activeIngredient} ${quality} ${
        medicineName ? `(${medicineName})` : ''
      }`
    }
  }
}
</script>

<style lang="scss" scoped>
.cs_down_icon {
  display: block;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.cs-relative {
  position: relative;
  z-index: 100000;
}

.table-container {
  padding-right: 5px;
  border-bottom: 1px solid #9b9b9b;
  min-height: 280px;
}

.table-header-container {
  padding-right: 5px;
  border-bottom: 1px solid #9b9b9b;
}

.cs-table {
  display: block;
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 10;
  border: 1px solid #9b9b9b;
  border-bottom: unset;
  margin: 0;
  max-height: 400px;
  border-radius: 0;
  z-index: 100000;
}

.cs-header {
  // border-bottom: 1px solid #9b9b9b;
  margin: 0;
}

.cs-border-right {
  border-right: 1px solid #9b9b9b;
}
.cs-border-bottom {
  border-bottom: 1px solid #9b9b9b;
}
.cs-header-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.cs-item {
  padding: 8px;
}

.cs-option:hover {
  cursor: pointer;
  background-color: rgba(29, 68, 177, 0.12);
}
.cs-option {
  margin: 0;
}
.cs-body {
  overflow-y: scroll;
  max-height: 300px;
  margin: 0;
  margin-right: -5px;
  position: relative;

  &::-webkit-scrollbar {
    background-color: red;
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;

    background: #888;
  }
}

::v-deep {
  .cs-input {
    .el-input__inner {
      padding-right: 45px;
    }
  }
}
</style>