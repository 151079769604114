<template>
  <div v-if="prescription && dialogVisible && prId">
    <el-dialog
      fullscreen
      :modal="false"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
    >
      <div class="relative">
        <div v-loading="loading">
          <div class="mb-3">
            <div class="text-center text-2xl font-semibold text-black">
              Chi tiết Đơn Thuốc
            </div>
          </div>
          <div class="my-3 text-base">
            <div class="row mb-2">
              <div class="col text-black">
                <span class="font-bold mr-1">Mã đơn thuốc:</span>
                <span>{{ `${prescription.prescription_code}` }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5 text-black">
                <div class="font-bold">Tên đơn thuốc</div>
                <div>{{ prescription.name }}</div>
              </div>
              <div class="col-md-4 text-black">
                <div class="font-bold">Ngày kê đơn</div>
                <div>{{ formatDate(prescription.date) }}</div>
              </div>
              <div class="col-md-3 text-black">
                <div class="font-bold">Bác sĩ kê đơn</div>
                <div>{{ prescription.prescribed_doctor }}</div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-5 text-black">
                <div class="font-bold">Cơ sở Y Tế</div>
                <div>{{ prescription.prescribed_clinic }}</div>
              </div>
              <div class="col-md-4 text-black">
                <div class="font-bold">Lời dặn của Bác sĩ</div>
                <div>{{ prescription.description }}</div>
              </div>
              <div class="col-md-3 text-black">
                <div class="font-bold">Ngày tạo</div>
                <div>{{ formatDate(prescription.created_at) }}</div>
              </div>
            </div>
          </div>

          <el-table
            class="text-black font-medium text-base w-full"
            :data="prescription.person_medicines"
            border
            :default-sort="{ prop: 'start_date', order: 'descending' }"
          >
            <el-table-column
              width="400"
              class-name="text-black text-base"
              label="Tên thuốc"
            >
              <template slot-scope="scope">
                <span>{{ handleGetMedicineName(scope.row) }}</span>
                <span>
                  {{ handleGetMedicineName(scope.row.medicine) }}
                  <!-- {{ scope.row.medicine && scope.row.medicine.name }} -->
                </span>
                <div class="text-sm truncate">
                  <span v-if="scope.row.side_effect"
                    >Tác dụng phụ: {{ scope.row.side_effect }}</span
                  >
                </div>
                <div class="text-sm truncate">
                  <span>Hướng dẫn: {{ scope.row.doctor_note }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column class-name="text-black text-base" label="ĐVT">
              <template slot-scope="scope">
                <span>{{
                  scope.row.medicine && scope.row.medicine.amount_unit
                }}</span>
                <span>{{ scope.row.amount_unit }}</span>
              </template>
            </el-table-column>
            <el-table-column class-name="text-black text-base" label="Số lượng">
              <template slot-scope="scope">
                <span>{{ formatNumber(scope.row.amount) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              class-name="text-black text-base"
              label="Lần / ngày"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.freq_per_day }} lần</span>
              </template>
            </el-table-column>
            <el-table-column
              class-name="text-black text-base"
              label="Lượng/lần"
            >
              <template slot-scope="scope">
                <span>
                  {{ formatNumber(scope.row.amount_per_one) }}
                  {{ scope.row.medicine && scope.row.medicine.amount_unit }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              class-name="text-black text-base"
              label="Lịch trình"
            >
              <template slot-scope="scope">
                <span>
                  {{
                    scope.row.gap_day &&
                    (scope.row.gap_day > 1
                      ? "Cách " + (Number(scope.row.gap_day) - 1)
                      : "Hàng")
                  }}
                  ngày
                </span>
              </template>
            </el-table-column>
            <!-- <el-table-column
          class-name="text-black text-base"
          label="Ngày bắt đầu"
          sortable
        >
          <template slot-scope="scope">
            <span >{{scope.row.start_date ? formatDate(scope.row.start_date) : ''}}</span>
          </template>
            </el-table-column>-->
            <!-- <el-table-column
          class-name="text-black text-base"
          label="Theo dõi"
        >
          <template slot-scope="scope">
            <div><input
                type="checkbox"
                v-model="scope.row.is_monitor"
                @click="changeDrugTracking(scope.row.id,scope.row.is_monitor, $event)"
                style="cursor: pointer;"
              ></div>
          </template>
            </el-table-column>-->
          </el-table>

          <div class="flex mt-3">
            <div class="ml-auto">
              <div class="flex">
                <div>
                  <button
                    type="button"
                    class="btn btn-light mr-1"
                    @click="handleClose"
                    :disabled="loading"
                  >
                    Đóng
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    class="btn bg-pri text-white mr-1"
                    @click="signDocument"
                    :disabled="loading || !!prescription.sign"
                  >
                    <div>{{ !!prescription.sign ? "Đã" : "" }} Ký</div>
                  </button>
                </div>
                <el-tooltip
                  content="Phiếu đã xác nhận, không thể sửa"
                  placement="top"
                  :disabled="!prescription.sign"
                >
                  <div>
                    <button
                      type="button"
                      class="btn bg-pri text-white mr-1"
                      @click="editPrescription(prescription.id)"
                      :disabled="loading || !!prescription.sign"
                    >
                      <div>Sửa đổi</div>
                    </button>
                  </div>
                </el-tooltip>
                <div>
                  <button
                    type="button"
                    class="btn bg-pri text-white mr-1"
                    @click="openUrl({ fileName: documentLink })"
                    :disabled="loading || !prescription.sign"
                  >
                    <div>Xem / In phiếu</div>
                  </button>
                </div>
              </div>
              <div class="ml-1 text-black" v-if="prescription.sign">
                ({{ prescription.sign.user.name }} đã xác nhận -
                {{ getDateTime(prescription.sign.sign_at) }})
              </div>
            </div>
          </div>
        </div>

        <ModalAddPrescription
          ref="ModalAddPrescription"
          @refresh="handleTriggerReloadEnvelope"
        />
        <div v-if="patientVisit && prescription">
          <TempPrescription :save_pdf="save_pdf" :formData="formDataTemp" />
        </div>
      </div>
    </el-dialog>
    <ModalSignPDF
      containerIDProps="PrescriptionContainerPDF"
      elementIDProps="PrescriptionSignerPDF"
      imageIDProps="PrescriptionImageID"
      ref="PrescriptionSignerPDF"
      :envelopeName="envelopeName"
      @onSuccess="handleSignDocumentSuccess"
    />
    <PdfViewer
      ref="DetailPrescriptionPdfViewer"
      containerIDProps="ContainerDetailPrescriptionPDFViewerID"
      elementIDProps="DetailPrescriptionPDFViewerID"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import html2pdf from 'html2pdf.js'
import ModalAddPrescription from './ModalAdd'
import appUtils from '../../../utils/appUtils'
import TempPrescription from '../../../templates/DonThuoc/TempPrescription'
import {
  DOCUMENT_SIGN_TYPES,
  ENVELOPE_DOCUMENT_TYPE,
  ENVELOPE_NAME_TYPE,
  SIGNATORY_ROLE
} from '../../../utils/constants'
import ModalSignPDF from '../../Signature/ModalSignPDF.vue'
import SignatureRequest from '../../../api/request/SignatureRequest'
import { mixinSignPdf } from '../../../utils/mixinSignPdf'
import PdfViewer from '../../PdfViewer/PdfViewer.vue'

export default {
  name: 'ModalViewPrescription',
  props: ['patientVisit'],
  components: {
    ModalAddPrescription,
    TempPrescription,
    ModalSignPDF,
    PdfViewer
  },
  mixins: [mixinSignPdf],
  data () {
    return {
      dialogVisible: false,
      prescription: {},
      loading: false,
      prId: 0,
      save_pdf: 0,
      documentLink: null,
      onPrinting: false,
      isSigning: false
    }
  },
  mounted () {
    this.$root.$on('onSavePDFToS3', this.savePdfToS3)
  },
  destroyed () {
    this.$root.$off('onSavePDFToS3')
  },
  computed: {
    formDataTemp () {
      let sign = null

      sign = {
        sign_at: window.moment(),
        signer_name: this.prescription?.prescribed_doctor,
        // uuid: this.prescription?.sign?.uuid,
        isDocSign: false
      }
      return {
        person: {
          ...(this.patientVisit?.person || {}),
          code:
            this.prescription?.person_info?.pid ||
            this.patientVisit?.person?.code
        },
        prescription: this.prescription,
        consultation: this.patientVisit?.consultation,
        sign,
        icd_name: this.patientVisit?.icd_name
      }
    },
    envelopeName () {
      return this.handleGetEnvelopeName(
        ENVELOPE_NAME_TYPE.patientVisit,
        this.patientVisit
      )
    }
  },
  watch: {
    dialogVisible (stt) {
      if (!stt) {
        let query = Object.assign({}, this.$route.query)
        if (query.id) {
          delete query.id
          this.$router.push({ query }).catch(() => {})
        }
      } else {
        let query = Object.assign({}, this.$route.query)
        if (this.prId) {
          query.id = this.prId
          this.$router.push({ query }).catch(() => {})
        }
      }
    },
    patientVisit (data) {
      if (data) {
        if (this.prId) {
          const _tempData = data?.prescription?.find(
            (item) => item.id === this.prId
          )
          if (!_tempData || typeof _tempData === 'undefined') return

          this.documentLink = _tempData.document_link
        }
      }
    }
  },
  methods: {
    formatNumber (value) {
      return +value
    },
    handleClose () {
      this.isSigning = false
      this.dialogVisible = false
      this.cancel()
    },
    async handleTriggerReloadEnvelope (id, triggerCreateEnvelope) {
      await this.getPrescriptionByIDData(id)
      if (triggerCreateEnvelope) {
        this.save_pdf++
        this.isSigning = triggerCreateEnvelope
        this.loading = true
      }
    },
    async openDialog (id, triggerCreateEnvelope) {
      this.prId = id
      this.dialogVisible = true
      this.$nextTick(async () => {
        if (id) {
          this.handleTriggerReloadEnvelope(id, triggerCreateEnvelope)
        }
      })
    },
    cancel () {
      this.prescription = []
    },
    formatDate (date) {
      return date && moment(date).format('DD/MM/YYYY')
    },
    async changeDrugTracking (id, status, e) {
      try {
        if (id) {
          const r = confirm('Bạn có chắc chắn thực hiện thao tác này?')
          if (r) {
            if (appUtils.isDoctor()) {
              await this.$rf
                .getRequest('DoctorRequest')
                .postPersonMedicinesMonitor(id)
                .then((res) => {})
            } else if (appUtils.isAdmin()) {
              await this.$rf
                .getRequest('AdminRequest')
                .postPersonMedicinesMonitor(id)
                .then((res) => {})
            }
          } else {
            e.target.checked = status
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    editPrescription () {
      this.$refs.ModalAddPrescription.openDialog(
        this.prescription,
        this.patientVisit
      )
    },
    isImage (file_name) {
      return file_name.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null
    },
    formatDateDMY (date) {
      return moment(date).format('HH:mm DD/MM/YYYY')
    },
    getLinkDownload (link) {
      return appUtils.getDocumentURL(link)
    },
    async getPrescriptionByIDData (id) {
      try {
        this.loading = true

        if (id) {
          const res = await this.$rf
            .getRequest('DoctorRequest')
            .getPrescriptionByIDV2(id)

          if (res?.data) {
            this.prescription = _.cloneDeep(res.data)
            this.prescription = {
              ...this.prescription,
              person_medicines: this.prescription.person_medicine
            }
            this.loading = false
            this.$emit('refresh')
          }

          return res.data
        }
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    },
    exportPdf () {
      this.loading = true
      setTimeout(async () => {
        let options = {
          margin: [7, 11, 7, 7],
          filename: `Don_Thuoc_${this.prescription?.name}_BN_${this.person?.name}.pdf`,
          html2canvas: {
            dpi: 1000,
            scale: 6,
            image: { type: 'png', quality: 1 },
            letterRendering: true,
            allowTaint: true,
            logging: true,
            useCORS: true
          },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        }
        try {
          await html2pdf()
            .from(this.$refs.TemplatePrescription)
            .set(options)
            .save()
        } catch (error) {
          console.log(error)
        }
        this.loading = false
      }, 50)
    },
    async handleCheckHasEnvelop () {
      const signEnvelope = await this.handleCheckSignEnvelope(
        ENVELOPE_DOCUMENT_TYPE.PRESCRIPTION,
        this.prescription?.id,
        'DetailPrescriptionPdfViewer',
        'PrescriptionSignerPDF'
      )
      this.isSigning = false

      return signEnvelope
    },
    async signDocument () {
      if (!this.prescription) return
      const signEnvelope = await this.handleCheckHasEnvelop()

      if (!signEnvelope?.id) {
        this.save_pdf++
        this.isSigning = true
        // const responseData = await this.handleProcessSignEnvelope()
        // if (responseData) {
        //   await this.handleCheckHasEnvelop()
        // }
      }
      // this.save_pdf++

      // if (!confirm(`Phiếu đã ký không thể sửa. Bạn có chắc chắn muốn ký?`)) {
      //   return
      // }
      // try {
      //   let params = {
      //     id: this.prescription.id,
      //     type_document: DOCUMENT_SIGN_TYPES.CODE.PRESCRIPTION,
      //     method: 1,
      //     inventory_id: this.prescription.pharma_inventory_id
      //   }
      //   await this.$rf.getRequest('DoctorRequest').signPrescription(params)

      //   await this.handleUpdateAvailable()
      //   await this.getPrescriptionByIDData(this.prescription.id).then(
      //     response => {
      //       this.save_pdf++
      //     }
      //   )

      //   this.$message({
      //     message: this.$t('Ký xác nhận thành công'),
      //     type: 'success'
      //   })
      //   this.$emit('refresh')
      // } catch (error) {
      //   console.log(error)
      //   this.loading = false
      // }
    },
    async handleUpdateAvailable () {
      if (!this.prescription.pharma_inventory_id) return

      const rawMedicines = this.prescription.person_medicines?.map((item) => ({
        amount: item?.amount,
        id: item?.pharma_inventory_product_id
      }))

      const params = {
        product_invent_ids: rawMedicines
      }

      try {
        await this.$rf.getRequest('DoctorRequest').postHandleAvailable(params)
      } catch (error) {
        console.log(error)
      }
    },
    async savePdfToS3 (base64) {
      if (!base64 || !this.prescription?.id) return

      try {
        this.loading = true

        const payload = {
          content: base64,
          fileName:
            `${appUtils.convertSlug(
              `${this.$t('Đơn Thuốc')} ${this.prescription?.created_at}`
            )}_` || 'prescription_',
          path: 'prescriptions',
          type: DOCUMENT_SIGN_TYPES.CODE.PRESCRIPTION,
          type_id: this.prescription?.id,
          uuid: this.prescription?.uuid
        }

        const res = await this.$rf
          .getRequest('DoctorRequest')
          .printDocument(payload)

        if (res?.data) {
          this.onPrinting = false

          await this.handleProcessSignEnvelope(res.data?.documentLink)
          this.loading = false

          if (this.isSigning) {
            this.handleCheckHasEnvelop()
          }
          // this.handleOpenSignPDF(res.data?.documentLink)
          // this.$emit('refresh')

          // if (this.onPrinting) {
          //   window.open(res.data?.documentLink, '_blank', 'noreferrer')
          //   this.onPrinting = false
          // }
        }
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    openLink (link) {
      if (!link) return
      link && window.open(link, '_blank', 'noreferrer')
    },
    async openUrl ({ fileName }) {
      window.open(fileName, '_blank')
    },
    handleGetMedicineName (medicine) {
      const medicineName = medicine?.name || medicine?.product_info?.name || ''
      const activeIngredient =
        medicine?.active_ingredient ||
        medicine?.product_info?.active_ingredient_info?.name ||
        ''
      const quality =
        medicine?.quality || medicine?.product_info?.quality || ''

      return `${activeIngredient} ${quality} ${
        medicineName ? `(${medicineName})` : ''
      }`
    },
    getDateTime (date) {
      if (!date) return ''
      return window
        .moment(date)
        .subtract(7, 'hour')
        .format('HH:mm:ss DD/MM/YYYY')
    },
    async handleSignDocumentSuccess (database64) {
      try {
        this.loading = true
        const file = appUtils.convertBase64tToFile(
          database64,
          parseInt(Date.now() / 1000)
        )
        const url = await this.uploadFiles(file, this.prescription?.id)

        await this.$rf
          .getRequest('DoctorRequest')
          .updatePrescriptionV2(this.prescription.id, { document_link: url })

        let params = {
          id: this.prescription.id,
          type_document: DOCUMENT_SIGN_TYPES.CODE.PRESCRIPTION,
          method: 1,
          inventory_id: this.prescription.pharma_inventory_id
        }
        await this.$rf.getRequest('DoctorRequest').signPrescription(params)

        await this.handleUpdateAvailable()

        // await this.getPrescriptionByIDData(this.prescription.id).then(
        //   response => {
        //     this.save_pdf++
        //   }
        // )

        // this.$message({
        //   message: this.$t('Ký xác nhận thành công'),
        //   type: 'success'
        // })
        const prescriptionData = await this.getPrescriptionByIDData(
          this.prescription.id
        )
        this.$emit('refresh')
        window.open(prescriptionData.document_link, '_blank', 'noreferrer')
      } catch (error) {
        console.log(error)
        this.loading = false
      } finally {
        this.loading = false
      }
    },
    async handleOpenSignPDF (url) {
      const procedureResponse = await this.getSignProcedure()
      const procedureSelected = procedureResponse?.data?.[0] || {}
      const signatories = procedureSelected?.sign_nodes?.reduce((arr, node) => {
        const signs = node?.node_signatories?.map((item) => {
          let user
          if (item?.user_role === 'doctor_exam') {
            user = this.prescription?.prescribed_doctor_info?.User
          }

          return {
            ...item,
            user,
            user_id: user?.id
          }
        })

        return arr.concat(signs)
      }, [])

      const signerInfo = signatories?.find(
        (item) => item?.user_id === this.$user?.id
      )
      if (!signerInfo) {
        this.$toast.open({
          type: 'error',
          message: 'Bạn không có quyền ký tài liệu này'
        })

        return
      }

      this.$refs.PrescriptionSignerPDF.handleOpen(
        url,
        ENVELOPE_DOCUMENT_TYPE.PRESCRIPTION,
        this.prescription.id,
        signerInfo?.user_id,
        signatories,
        null,
        procedureSelected?.id
      )
    },
    async getSignProcedure () {
      try {
        const params = {
          page_num: 1,
          page_size: 1000,
          order: 'desc',
          sort_by: 'id',
          document_type: ENVELOPE_DOCUMENT_TYPE.PRESCRIPTION,
          ws_id: this.$globalClinic?.id
        }

        const request = new SignatureRequest()

        const response = await request.getSignProcedures(params)
        return response.data
      } catch (error) {
        console.log(error)
      }
    },
    async uploadFiles (file, id) {
      const params = {
        file,
        FolderTarget: `prescription`,
        IdTarget: id
      }
      const formData = new FormData()

      for (const key in params) {
        formData.append(key, params[key])
      }

      const res = await this.$rf
        .getRequest('DoctorRequest')
        .uploadFileTenant(formData)
      return res.data.fileUrl
    },
    async handleProcessSignEnvelope (documentUrl) {
      let responseData
      const procedureSelected = await this.getSignProcedures(
        ENVELOPE_DOCUMENT_TYPE.PRESCRIPTION
      )
      const signatories = procedureSelected?.sign_nodes?.reduce((arr, node) => {
        const signs = node?.node_signatories?.map((item) => {
          let user
          if (item?.user_role === 'doctor_exam') {
            user = this.prescription?.prescribed_doctor_info?.User
          }
          // const user = this.getUserByRole(item?.user_role)

          return {
            ...item,
            user,
            user_id: user?.id,
            node_signatory_id: item?.id,
            signatory_name: user?.name,
            signatory_email: user?.email,
            signatory_role: SIGNATORY_ROLE.signer,
            signatory_order: item?.sign_order,
            role_name: item?.role_name,
            is_required: item?.is_required,
            expired_date: item?.expired_date || undefined
          }
        })

        return arr.concat(signs)
      }, [])

      const signEnvelope = await this.handleGetEnvelopeByDocumentID(
        this.prescription?.id,
        ENVELOPE_DOCUMENT_TYPE.PRESCRIPTION
      )

      if (signEnvelope?.id) {
        responseData = await this.handleUpdateSignEnvelope(
          signEnvelope.id,
          signatories,
          documentUrl,
          ENVELOPE_DOCUMENT_TYPE.PRESCRIPTION,
          this.prescription?.id,
          this.envelopeName
        )

        return responseData
      } else {
        responseData = await this.handleCreateEnvelope(
          signatories,
          documentUrl,
          procedureSelected?.id,
          ENVELOPE_DOCUMENT_TYPE.PRESCRIPTION,
          this.prescription?.id,
          this.envelopeName
        )
        return responseData
      }
    }
  }
}
</script>

<style lang="scss"></style>
